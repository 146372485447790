<style scoped>
.btn-el-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  border: 2px solid #0016b0;
}
.btn-el-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.btn__actualizar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  font-weight: 500 !important;
  font-size: 1em !important;
  letter-spacing: 1px;
}
</style>

<template>
  <div>
    <CRow>
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      ></loading>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Actualización de Datos Personales</strong>
          </CCardHeader>
          <CCardBody>
            <div v-if="state == 1 || state == 3">
              <div class="form-group col-md-7">
                <label for="">Teléfono principal [celular/fijo]</label>
                <p v-if="telefonoCelular == ''">No Tiene</p>
                <p v-else>{{ telefonoCelular }}</p>
              </div>

              <div class="form-group col-md-7">
                <label for="">Teléfono adicional [celular]</label>
                <p v-if="telefonoFijo == ''">No Tiene</p>
                <p v-else>{{ telefonoFijo }}</p>
              </div>

              <div class="form-group col-md-7">
                <label for="">Correo electrónico</label>
                <p v-if="correo == ''">No Tiene</p>
                <p v-else>{{ correo }}</p>
              </div>

              <div class="form-group col-md-7">
                <button
                  type="button"
                  class="btn-el-comercio btn__actualizar"
                  @click="$bvModal.show('modal-lg') + setValModal()"
                >
                  Actualizar
                </button>
              </div>

              <div v-if="state == 3">
                <p style="color: green">
                  {{ firstName }}, su última solicitud de actualización de datos
                  de contacto fue actualizado correctamente.
                </p>
              </div>
            </div>
            <div v-else-if="state == 2">
              <p>
                {{ firstName }}, lo sentimos pero tiene una solicitud pendiente
                de actualización.
              </p>
            </div>
            <CCard>
              <CCardHeader>
                <div class="card-header-actions">
                  <a
                    href="https://coreui.io/vue/docs/components/card-components"
                    class="card-header-action"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                  </a>
                </div>
              </CCardHeader>
              <CCardBody class="p-4"> </CCardBody>
            </CCard>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <img class="avatar__alicia" src="../../assets/img/nueva_alicia_pages_all.png" />
    <img class="avatar__alicia__mobile" src="../../assets/img/nueva_alicia_pages_all.png" />

    <b-modal
      id="modal-lg"
      size="lg"
      hide-footer
      no-close-on-backdrop
      hide-header-close
      no-close-on-esc
    >
      <template #modal-title> ACTUALIZA TUS DATOS PERSONALES: </template>
      <div class="form-group col-md-12">
        <label for="">Teléfono principal [celular/fijo]</label>
        <p
          v-if="isTelefonoCelular"
          style="color: red; font-size: 0.8em; margin: 0 0 5px 0; padding: 0"
        >
          (*)El campo telefono principal está vacio.
        </p>
        <CInput
          placeholder="Teléfono principal"
          class
          maxlength="9"
          v-model="telefonoCelular_update"
          @keypress="validateNumber($event)"
        ></CInput>
      </div>

      <div class="form-group col-md-12">
        <label for="">Teléfono adicional [celular]</label>
        <p
          v-if="isTelefonoFijo"
          style="color: red; font-size: 0.8em; margin: 0 0 5px 0; padding: 0"
        >
          (*)El campo telefono adicional está vacio.
        </p>
        <CInput
          placeholder="Teléfono adicional"
          class
          maxlength="9"
          v-model="telefonoFijo_update"
          @keypress="validateNumber($event)"
        ></CInput>
      </div>

      <div class="form-group col-md-12">
        <label for="">Correo electrónico</label>
        <p
          v-if="isCorreo"
          style="color: red; font-size: 0.8em; margin: 0 0 5px 0; padding: 0"
        >
          (*)El campo correo electrónico está vacio.
        </p>
        <CInput
          placeholder="Correo electrónico"
          class
          maxlength="30"
          v-model="correo_update"
        ></CInput>
      </div>

      <div class="form-group col-md-7">
        <button
          type="button"
          class="btn-el-comercio btn__actualizar"
          @click="sendPersonalData()"
        >
          Actualizar
        </button>
      </div>
      <span style="padding-left: 1.5em">¡Espero haberte ayudado!</span>
      <br />
      <b-button
        class="mt-3"
        block
        @click="$bvModal.hide('modal-lg')"
        style="padding: 7px; border-radius: 3px"
        >Cerrar</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Cards",
  data: function () {
    return {
      show: true,
      correo: "",
      telefonoCelular: "",
      telefonoFijo: "",
      firstName: localStorage.getItem("name").split(" ")[0],
      correo_update: "",
      telefonoCelular_update: "",
      telefonoFijo_update: "",
      isCollapsed: true,
      isLoading: false,
      fullPage: true,
      isCorreo: false,
      isTelefonoCelular: false,
      isTelefonoFijo: false,
      state: "-1",
    };
  },
  components: {
    Loading,
  },
  methods: {
    setValModal() {
      let vm = this;
      vm.correo_update = vm.correo;
      vm.telefonoCelular_update = vm.telefonoCelular;
      vm.telefonoFijo_update = vm.telefonoFijo;

      if (vm.correo_update != "") {
        flagCorreo = true;
        vm.isCorreo = false;
      } else {
        vm.isCorreo = true;
      }

      if (vm.telefonoCelular_update != "") {
        flagTelefonoCelular = true;
        vm.isTelefonoCelular = false;
      } else {
        vm.isTelefonoCelular = true;
      }

      if (vm.telefonoFijo_update != "") {
        flagTelefonoFijo = true;
        vm.isTelefonoFijo = false;
      } else {
        vm.isTelefonoFijo = true;
      }
    },
    listPersonalData() {
      let vm = this;
      let data = {
        nro_documento: localStorage.getItem("numeroDocumento"),
        tipo_documento: localStorage.getItem("tipoDocumento"),
      };
      axios
        .post(`${process.env.VUE_APP_ROOT_API}/listarDatosSuscriptor`, data)
        .then(function (res) {
          console.log("Res =>", res);
          vm.isLoading = false;
          if (res.data.data.code == "0") {
            vm.$fire({
              title: "Información",
              text: "Ocurrio un error",
              type: "info",
              timer: 4000,
            }).then((r) => {
              console.log("dismin", r);
            });
          } else {
            vm.correo = res.data.data.correo;
            vm.telefonoCelular = res.data.data.landing.phone;
            vm.telefonoFijo = res.data.data.landing.secondPhone;
            vm.state = res.data.data.estado;
            console.log(vm.state);
            if (vm.state == "1") {
              if (
                vm.correo == "" ||
                vm.telefonoFijo == "" ||
                vm.telefonoCelular == ""
              ) {
                vm.$bvModal.show("modal-lg");
                vm.setValModal();
              }
              if (vm.correo == "") {
                vm.isCorreo = true;
              }
              if (vm.telefonoCelular == "") {
                vm.isTelefonoCelular = true;
              }
              if (vm.telefonoFijo == "") {
                vm.isTelefonoFijo = true;
              }
            }
          }
        })
        .catch((error) => {
          vm.isLoading = false;
        });
    },
    validateNumber(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    sendPersonalData() {
      let vm = this;
      let flagCorreo = false;
      let flagTelefonoCelular = false;
      let flagTelefonoFijo = false;
      if (vm.correo_update != "") {
        flagCorreo = true;
        vm.isCorreo = false;
      } else {
        vm.isCorreo = true;
      }
      if (vm.telefonoCelular_update != "") {
        flagTelefonoCelular = true;
        vm.isTelefonoCelular = false;
      } else {
        vm.isTelefonoCelular = true;
      }
      if (vm.telefonoFijo_update != "") {
        flagTelefonoFijo = true;
        vm.isTelefonoFijo = false;
      } else {
        vm.isTelefonoFijo = true;
      }
      if (
        vm.telefonoCelular_update.length == 7 ||
        vm.telefonoCelular_update.length == 9
      ) {
        flagTelefonoCelular = true;
      } else {
        flagTelefonoCelular = false;
      }
      if (vm.telefonoFijo_update.length == 9) {
        flagTelefonoFijo = true;
      } else {
        flagTelefonoFijo = false;
      }
      if (
        flagCorreo == true &&
        flagTelefonoCelular == true &&
        flagTelefonoFijo == true
      ) {
        let data = {
          document_type: localStorage.getItem("tipoDocumento"),
          document_number: localStorage.getItem("numeroDocumento"),
          email: vm.correo_update,
          phone_number: vm.telefonoCelular_update,
          phone_number_secundary: vm.telefonoFijo_update,
          platformOrigin: "Landing",
        };
        //console.log("Data a actualizar: ", data);
        axios
          .post(`${process.env.VUE_APP_ROOT_API}/actualizarSuscriptor`, data)
          .then(function (res) {
            //console.log("Respuesta actualizar=>", res);
            vm.code = res.data.data.code;
            if (vm.code == "1") {
              //SE VA A ACTUALIZAR
              let data = {
                nro_documento: localStorage.getItem("numeroDocumento"),
                tipo_documento: localStorage.getItem("tipoDocumento"),
              };
              axios
                .post(
                  `${process.env.VUE_APP_ROOT_API}/listarDatosSuscriptor`,
                  data
                )
                .then(function (res) {
                  vm.isLoading = false;
                  vm.code = res.data.data.code;
                  vm.state = res.data.data.estado;
                  let correo = res.data.data.correo;
                  console.log("RES LIST SUSCRIPTOR =>", res);
                  if (vm.state != "1") {
                    let message =
                      String(vm.firstName) +
                      ", en el transcurso del día se le enviará un correo a" +
                      String(vm.correo_update) +
                      " confirmando su actualización.";
                    vm.$fire({
                      title: "Felicitaciones",
                      html: message,
                      type: "success",
                      timer: 4000,
                    }).then((r) => {
                      console.log("dismin", r);
                    });
                    vm.$bvModal.hide("modal-lg");
                  } else {
                    vm.$fire({
                      title: "Información",
                      html: "Ocurrio un error en el servidor",
                      type: "info",
                      timer: 4000,
                    }).then((r) => {
                      console.log("dismin", r);
                    });
                  }
                })
                .catch((error) => {
                  vm.isLoading = false;
                  vm.$fire({
                    title: "Error",
                    html: "Ocurrio un error en el servidor",
                    type: "error",
                    timer: 4000,
                  }).then((r) => {
                    console.log("dismin", r);
                  });
                });
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            } else if (vm.code == "2") {
              //ESTA EN PROCESO DE ACTUALIZACION
              vm.isLoading = false;
              let message =
                String(vm.firstName) +
                ", en el transcurso del día se le enviará un email al último correo que actualizó confirmando su actualización.";
              vm.$fire({
                title: "Seguridad",
                html: message,
                type: "info",
                timer: 4000,
              }).then((r) => {
                console.log("dismin", r);
              });
            } else if (vm.code == "4") {
              //ESTA ENVIANDO LOS MISMOS DATOS
              vm.isLoading = false;
              vm.$fire({
                title: "Seguridad",
                html: "Los campos que quiere actualizar son los que tiene actualmente.",
                type: "info",
                timer: 4000,
              }).then((r) => {
                console.log("dismin", r);
              });
            } else {
              vm.isLoading = false;
              vm.$fire({
                title: "Seguridad",
                html: "Vuelva a intentarlo otra vez.",
                type: "info",
                timer: 4000,
              }).then((r) => {
                console.log("dismin", r);
              });
            }
          })
          .catch((error) => {
            vm.isLoading = false;
            vm.$fire({
              title: "Seguridad",
              html: "Ocurrio un error en el servidor.",
              type: "error",
              timer: 4000,
            }).then((r) => {
              console.log("dismin", r);
            });
          });
      } else if (flagTelefonoCelular == false) {
        vm.$fire({
          title: "Información",
          html: "Debe ingresar 7 o 9 dígitos en el campo teléfono principal.",
          type: "info",
          timer: 4000,
        }).then((r) => {
          console.log("dismin", r);
        });
      } else if (flagTelefonoFijo == false) {
        vm.$fire({
          title: "Información",
          html: "Debe ingresar 9 dígitos en el campo teléfono celular",
          type: "info",
          timer: 4000,
        }).then((r) => {
          console.log("dismin", r);
        });
      }
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();
    vm.listPersonalData();
  },
};
</script>
